import axios from "axios";
import { type Ref } from 'vue';
import ContactMail from './ContactMail';

export default {
  sendMail: async (mail: Ref<ContactMail>) => {
    try {
      var result = await axios.post<boolean>("https://api.wilderminds.com/api/messenger?template=ContactTemplate.txt", mail.value);
      return result.data;
    } catch {
      return false;
    } 
  }
};